
.Switch {
    position: relative;
    display: inline-block;
    font-size: 1.6em;
    font-weight: bold;
    color: #ccc;
    text-shadow: 0px 1px 1px rgba(255,255,255,0.8);
    height: 18px;
    padding: 6px 6px 5px 6px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
    background: #ececec;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.1), inset 0px 1px 3px 0px rgba(0,0,0,0.1);
    cursor: pointer;
    font-size: 16px;
}

body.IE7 .Switch {
    width: 78px;
}

.Switch span {
    display: inline-block;
    width: 35px;
}

    .Switch span.On {
        color: #33d2da;
    }

.Switch .Toggle {
    position: absolute;
    top: 1px;
    width: 37px;
    height: 25px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 4px;
    background: #fff;
    background: -moz-linear-gradient(top, #ececec 0%, #ffffff 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ececec), color-stop(100%, #ffffff));
    background: -webkit-linear-gradient(top, #ececec 0%, #ffffff 100%);
    background: -o-linear-gradient(top, #ececec 0%, #ffffff 100%);
    background: -ms-linear-gradient(top, #ececec 0%, #ffffff 100%);
    background: linear-gradient(top, #ececec 0%, #ffffff 100%);
    box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5);
    z-index: 999;
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
}

.Switch.On .Toggle {
    left: 2%;
}

.Switch.Off .Toggle {
    left: 54%;
}
/* Round Switch */
.Switch.Round {
    padding: 0px 20px;
    border-radius: 40px;
}

body.IE7 .Switch.Round {
    width: 1px;
}

.Switch.Round .Toggle {
    border-radius: 40px;
    width: 14px;
    height: 14px;
}

.Switch.Round.Off .Toggle {
    left: 3%;
    background: #33d2da;
}

.Switch.Round.On .Toggle {
    left: 58%;
}

.Switch .tooltiptext {
    background: #333;
    bottom: 100%;
    color: #fff;
    display: block;
    left: -25px;
    margin-bottom: 15px;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    position: absolute;
    width: 97px;
    text-shadow: 0 0 0 #000;
    font-weight: 400;
    font-size: 12px;
    z-index: 6666;
    text-align: center;
    border-radius: 3px;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

    .Switch .tooltiptext:before {
        bottom: -20px;
        content: " ";
        display: block;
        height: 20px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .Switch .tooltiptext:after {
        border-left: solid transparent 10px;
        border-right: solid transparent 10px;
        border-top: solid #333 10px;
        bottom: -10px;
        content: " ";
        height: 0;
        left: 50%;
        margin-left: -13px;
        position: absolute;
        width: 0;
    }

.Switch:hover .tooltiptext {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .Switch .tooltiptext {
    display: none;
}

.lte8 .Switch:hover .tooltiptext {
    display: block;
}
