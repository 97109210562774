#ResourceListContainer {
    max-height: 200px;
    position: relative;
    overflow: overlay;
    border: 1px solid #e7e7e7;
  }
  
  .productSeletionBarContainer {
    max-height: 120px;
    position: relative;
    overflow: overlay;
    border: 1px solid #e7e7e7;
    padding-bottom: 10px;
    padding-left: 5px;
  }
  
  #plusMinusButtonDiv {
    margin-top: 10px;
  }
  
  #minusDiv {
    margin-left: 5px;
  }
  
  .productSeletionBarSpanMain {
    padding: 5px;
  }
  
  .productSeletionBarSpan1 {
    margin: 10px 0 0 0;
  }
  
  .submitBtnText {
    float: left;
    padding: 5px;
  }
  /* .Polaris-ResourceList__CheckableButtonWrapper{
      margin-top: -36px;
  } */
  .Polaris-ResourceList__HeaderWrapper.Polaris-ResourceList__HeaderWrapper--hasSelect {
    display: none;
  }
  .thead-th {
    font-size: 16px;
  }
  .table-thead {
    margin-top: 50px;
    text-align: left;
  }
  .custom-margin-bottom {
    margin-bottom: 10px;
  }
  
  /* ----------overlay--------------- */
  .list-page-overlay {
    background: transparent;
    opacity: 0.6;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 999;
    pointer-events: none;
  }
  .list-page-overlay-visible {
    display: block;
    opacity: 1;
    height: 100%;
    width: 100%;
    z-index: 999999;
    pointer-events: visible;
  }
  .loading-spinner {
    margin-top: 23%;
    margin-left: 25%;
    position: fixed;
  }
  .loading-modal-spinner {
    position: fixed;
    margin-top: 1%;
    margin-left: 20%;
  }
  .Polaris-Button__Text a {
    color: white;
    text-decoration: none;
  }
  /* -----------------OrderList-------------------- */
  
  .orderList__orderColumn,
  .orderList__itemsColumn {
    width: 7%;
    word-break: break-word;
    padding: 0 2px;
  }
  .orderList__dateColumn,
  .orderList__customerColumn {
    width: 18%;
    word-break: break-word;
    padding: 0 2px;
  }
  .orderList__totalColumn,
  .orderList__paymentColumn,
  .orderList__fulfillmentColumn,
  .orderList__deliveryColumn,
  .orderList__tagsColumn {
    width: 10%;
    word-break: break-word;
    padding: 0 2px;
  }
  /* ------------------------------------------------ */
  
  /* ----------------Order Detail-------------------- */
  
  .orderDetail__productColumn {
    width: 40%;
    word-break: break-word;
    padding: 0 2px;
  }
  .orderDetail__unitPriceColumn {
    width: 10%;
  }
  .orderDetail__quantityColumn,
  .orderDetail__totalColumn {
    width: 20%;
    text-align: center;
  }
  .orderDetail__quantityColumn1 {
    width: 80%;
    text-align: center;
  }
  .orderDetail__quantityColumn2 {
    width: 20%;
  }
  .orderDetail__deleteColumn {
    width: 10%;
  }
  #orderdetailpageresourcelist .Polaris-ResourceItem__Container{
    cursor: default !important;
  }
  /* ------------------------------------------------ */
  .OrderDetailPageWidth .Polaris-Page__Content .Polaris-Layout{
    margin-left: -40px !important;
    margin-right: -20px !important;
  }
  
  .lineitemstrike{
    text-decoration-line: line-through;
    display: block;
  }
  .boldtext{
    font-weight: bold;
  }