
.Polaris-DataTable .plans-icon-tr td{position: sticky;
    top: -1px;
    z-index: 5;}
    .Polaris-DataTable .plans-icon-tr .Polaris-Heading{    display: flex;
        align-items: center;}

.subscribeBtn{margin-left: 30% !important;}

.col-header-p-tag {
    margin: 0 !important;
}

.help-icon {
    margin-left: 5px;
}