.nav-plan {
    padding: 2rem 1.6rem;
}

.main{display: flex;}

.page-container {
    margin-left: 24rem;
    width: 100%;
}
.Polaris-Navigation__Section{
    background: white;
    height: 500px;
}

.Polaris-Navigation {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: calc(24rem + env(safe-area-inset-left));
    max-width: 24rem;
    height: auto;
    min-height: 100%;
    background-color: #fff !important;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(0px + env(safe-area-inset-bottom));
}
.align-item-center{ text-align: center !important;}
.hide-in-desktop { display: none !important;}

@media only screen and (max-width:48.0625em) {
    .btn-close-nav{ display: inline-flex !important;}
	.Polaris-Navigation {
		display: none !important;
		z-index: 30 !important;
		max-width: 100% !important;
		width: 100% !important; 
    }
    
    .Polaris-Navigation {
        border-right: var(--p-override-none,.1rem solid var(--p-border-subdued,#dfe3e8));
        max-width: 24rem;
        max-width: calc(24rem + constant(safe-area-inset-left));
        max-width: calc(24rem + env(safe-area-inset-left));
    }
    .page-container {
        margin-left: 0;
    }

    .btn-open-nav-container {
        display: block!important;
    }
}

@media only screen and (max-width:59.375em) {
    .text-center{ text-align: center;}
    .whitespace-wrap{ white-space: break-spaces;}
    .hide-in-mobile { display: none;}
    .hide-in-desktop { display: block !important;}
    .mobhead{ background-color: #f4f4f4; padding: 10px; text-align: center; font-weight: bold; color: #6b6a6a; display: table-row;height: 40px;}
}
@media only screen and (max-width:50.9375em) {
    .hide-in-desktop,.plan-row, .hide-in-mobile{ display: none ;}
    .hide-in-desktop.mobhead{ display: none !important;}
    .Polaris-DataTable__Cell--header{ display: block !important;}
}
/* .layout-item {
    padding: 0 1.6rem !important;
} */
.Polaris-DisplayText.Polaris-DisplayText--sizeLarge.page-title{ color: #000;}
@media only screen and (max-width: 30.625em){
    .Polaris-Page { padding: 0 2rem !important;}
}

.funnel-logo{ max-width: 65px; margin: 10px auto; display: block;}
table .Polaris-Icon{
    margin: 0 auto !important;
}
.Polaris-Page .Polaris-Page {
    padding: 0 !important;
}

.Polaris-Page {
    margin: 0 auto;
    max-width: 99.8rem;
}
@media only screen and (min-width:46.5em) {
    .Polaris-Page {
        padding: 0 3.2rem !important;
    }
}

.Polaris-Navigation {
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: 10;
}
.Polaris-Layout{
    margin-top:0px !important;
    margin-left: 0px !important;
    padding: 0 2rem !important;
}

.open-menu {
    display: flex !important;
}
@media only screen and (min-width:46.5em) {
.Polaris-EmptyState__Content{ font-size: 1em !important; line-height: 2rem !important; }
.Polaris-EmptyState__Content p.large_font {font-size: 2rem !important;margin: 20px 0;
    line-height: 2.8rem;}
    .Polaris-EmptyState__Content p.small_font{margin: 10px 0;}
    .Polaris-EmptyState--withinPage .Polaris-EmptyState__Details { width: 94% !important;}
}

.badge-wrapper {
    position: absolute;
    top: -12px;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    left: 7.5%;
}

.Polaris-DataTable__Table td:nth-child(3),
.Polaris-DataTable__Table th:nth-child(3){
    background-color:#ebedf8!important;
}

@media only screen and (max-device-width: 768px) {
    .badge-wrapper {
        top: 340px !important;
        left: 0 !important;
    }
}

@media only screen and (max-device-width: 480px) {
    .badge-wrapper {
        top: 333px !important;
        left: 0 !important;
    }
}

.center-align {
    margin-left: auto !important;
    margin-right: auto !important;
}