.Polaris-CalloutCard__Content img{
    display: block;
    vertical-align: middle;
    border-style: none;
    width: 12rem;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .logo {
    width: 6.5rem !important;
    padding-top: 8px;
    padding-bottom: 15px;
  }

  .row {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -2rem;
}
.text-center {
    text-align: center!important;
}
.col, [class*=col-] {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-top: 2rem;
}
.col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

}

.col-links-wrapper {
    width: 100%;
    float: left;
}

.col-links-33 {
    width: 33%;
    float: left;
    margin: 0;

    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;position: relative;
}

.col-links-34 {
    width: 34%;
    float: left;
    margin: 0;

    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;position: relative;
}

.col-no-padding-left {
    padding-left: 0;
}

.col-no-padding-right {
    padding-right: 0;
}

@media only screen and (max-device-width: 768px) {
    .col-links-33, .col-links-34 {
        width: 100% !important;
        padding: 0;
        margin-top: 20px;
    }
}

@media only screen and (max-device-width: 480px) {
    .col-links-33, .col-links-34 {
        width: 100% !important;
        padding: 0;
        margin-top: 20px;
    }
}